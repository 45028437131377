import Loader from "/WebInterface/new-ui/modules/loader/loader.js";
import { toast, growl } from "/WebInterface/new-ui/modules/toast.js";
import { Prompt } from "/WebInterface/new-ui/modules/alert.js";
import { t } from "/WebInterface/new-ui/modules/localizations/localizations.js";
import { setLanguage } from "/WebInterface/new-ui/modules/localizations/localizations.js";
import { _$ } from "/WebInterface/new-ui/assets/js/utils/dom.js";
import {
  isLoggedIn,
  resetPassword,
  changePassword,
} from "/WebInterface/new-ui/assets/js/utils/api/apis.js";
import { ajax } from "/WebInterface/new-ui/assets/js/utils/ajax.js";

let loggedIn = false;
let attempts = 0;
let bodyLoaderWhileLoginFromQS;
let altKeyPressed;
let metaKeyPressed;

const getQuerystring = (name) => {
  if (!name) return false;
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  let regexS = "[\\?&]" + name + "=([^&#]*)";
  let regex = new RegExp(regexS);
  let results = regex.exec(window.location.search);
  if (results == null) return "";
  else return decodeURIComponent(results[1].replace(/\+/g, " "));
};

const login = (e) => {
  const redirectToAdmin = altKeyPressed;
  const redirectToUserManager = metaKeyPressed;

  if (
    window.cookiePolicyAcceptedToLogin &&
    !localStorage.getItem("acceptCookies")
  ) {
    // growl("Error",("AcceptCookiesToContinue"), 5000, "", false);
    // if(e){
    //     e.preventDefault()
    //     e.stopPropagation();
    // }
    //_$("#loginWheel").hide();
    // if($(".accept-cookies:visible").length>0){
    //    _$(".accept-cookies:visible").addClass('accept-cookies-fixed');
    // }
    // else{
    //     acceptCookies.show(true);
    // }
    // return false;
  }
  if (window.force_eula && window.eula_line && !$("#tandc").is(":checked")) {
    // growl("Error",("AcceptEULAToContinue"), 5000, "", false);
    // if(e){
    //     e.preventDefault()
    //     e.stopPropagation();
    // }
    //_$("#loginWheel").hide();
    // return false;
  }
  //_$("#loginWheel").show();
  //_$("#btnLogin").addClass('disabled');
  // growl(L("AuthenticatingMsgText"), "", 5000, "", false);
  // window.curUser = false;
  // window.curPass = false;
  let username = _$("#username").val();
  if (!username) username = _$("input[name=username]").val();
  let password = _$("#password").val();

  const charsToCheck = window.unsafechars
    ? window.unsafechars.split().join("|")
    : "";
  if (charsToCheck && new RegExp(charsToCheck).test(password)) {
    toast({
      type: "error",
      position: "top-right",
      title: `Invalid character:  '${window.unsafechars}'`,
      autoClose: true,
      delay: 5000,
    });
    utils.electronEvent("loginerror", {
      message: `InvalidPasswordCharacterMsgText '${window.unsafechars}'`,
    });
    return false;
  }
  if (username.includes("/") || username.includes("\\")) {
    growl(t("invalidUserName"), t("invalidUserNameDetails"), 5000, true);
    _$("#login").removeClass("is-hidden");
    bodyLoaderWhileLoginFromQS?.deleteLoader();
    //_$("#btnLogin").removeClass('disabled');
    utils.electronEvent("loginerror", {
      message: "InvalidUsernameCharacterMsgText" + " '/ \\'",
    });
    return false;
  }
  if (!password) password = _$("input[name=password]").val();
  if (window.smsOTP) {
    if (window.isChanllenge) {
      password = `${window.smsOTP}`;
    } else {
      password = `${password}:${window.smsOTP}`;
    }
  }
  const curLang = utils.storage.get("_i18n") || "en";
  const obj = {
    command: "login",
    username: encodeURIComponent(username),
    password: encodeURIComponent(password),
    encoded: true,
    language: curLang,
    random: Math.random(),
  };
  if (window.smsOTP) {
    if (window.cachedCaptcha) {
      obj["g-recaptcha-response"] = window.cachedCaptcha + "";
    }
  }

  if (_$("#recaptcha_response_field").sel().length > 0)
    obj.recaptcha_response_field = utils.encodeURILocal(
      _$("#recaptcha_response_field").val()
    );
  if (_$("#recaptcha_challenge_field").sel().length > 0)
    obj.recaptcha_challenge_field = utils.encodeURILocal(
      _$("#recaptcha_challenge_field").val()
    );
  if (
    _$("#g-recaptcha-response").sel().length > 0 &&
    !obj["g-recaptcha-response"]
  )
    obj["g-recaptcha-response"] = _$("#g-recaptcha-response").val();
  else if (window.captchaToken && !obj["g-recaptcha-response"]) {
    obj["g-recaptcha-response"] = window.captchaToken;
  }
  if (obj["g-recaptcha-response"]) {
    window.cachedCaptcha = obj["g-recaptcha-response"] + "";
  }
  const formBody = Object.entries(obj)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");

  const loading = new Loader({
    parentElement: "#authFormContainer",
  });

  ajax
    .call({
      data: formBody,
      urlEncoded: true,
    })
    .always(() => {
      loading.deleteLoader();
    })
    .then((resp) => {
      const xml = new DOMParser().parseFromString(resp, "text/xml");
      const result = utils.xmlToJson(xml);
      let type;
      let msg;
      let c2f;
      if (result && result.loginResult) {
        const { response, message } = result.loginResult;
        c2f = result.loginResult.c2f;
        type = response.toLowerCase();
        if (message)
          msg = decodeURIComponent(message).replace(/\r?\n|\r/g, "<br>");
      }
      utils.storage.set("mainServerInstance", "");
      _$("#dummyIframe").attr("src", "javascript:void(0);");
      if (type == "recaptcha") {
        _$("#login").removeClass("is-hidden");
        bodyLoaderWhileLoginFromQS?.deleteLoader();
        growl(t("pleaseValidateCaptchaToLogin"), "", 5000);
        if (!utils.cache.get("recaptcha-added")) {
          utils.cache.set("recaptcha-added", true);
          showRecaptcha("recaptcha_div", () => {
            setTimeout(() => {
              _$("#loginForm").el().submit();
            }, 100);
            return false;
          });
        }
        return false;
      } else if (type == "success") {
        utils.electronEvent("loginsuccess", {
          message: "Logged in, setting up now.",
        });
        utils.electronEvent(
          "save-url",
          `${location.protocol}//${location.host}?u=${obj.username}&p=${
            obj.password
          }&path=${utils.queryString("path") || "/"}`
        );
        _$(
          "#username, #password, input[name=username], input[name=password]"
        ).val("");
        const queryStrings = utils.URLVars();
        utils.storage.remove("wi_upload_count");
        for (const key in queryStrings) {
          if (key.toLowerCase() === "wi_upload_count") {
            //key.toLowerCase().indexOf("meta_")===0 ||
            utils.storage.set(key.toLowerCase(), queryStrings[key]);
          }
        }
        if (_$("#remember").prop("checked")) {
          utils.storage.set("lastUserName", encodeURIComponent(username));
        } else {
          utils.storage.remove("lastUserName");
        }
        let link = false;
        if (c2f) {
          const options = {
            path: "/",
          };
          if (window.location.protocol.toLowerCase().indexOf("https") == 0)
            options.secure = true;
          utils.cookie.set("currentAuth", c2f, options);
        }
        if (location.href.includes("?link="))
          link = location.href.substring(
            location.href.indexOf("link=") + "link=".length
          );
        if (window.isAlternateRedirect) {
          link = "/";
        }
        if (link && link.length > 0 && !unescape(link).includes(":")) {
          link = utils.sanitize(link);
          window.location = link;
          return false;
        } else {
          growl(`${t("loadingWebInterface")}...`, t("success"), 30000);
          _$(
            "#username, #password, input[name=username], input[name=password]"
          ).val("");
          let path = "/";
          let redirectPath = utils.queryString("path") || path;
          if (redirectPath) redirectPath = utils.sanitize(redirectPath);
          if (location.href.includes("/Web" + "Interface/login.html"))
            path = `${location.href.substring(
              0,
              location.href.indexOf("/Web" + "Interface/login.html")
            )}`;
          //Web+Interface must be split up to work with reverse proxy
          else path = location.href.replace("/#/", redirectPath);

          if (redirectToUserManager)
            path = "/WebInterface/UserManager/index.html";
          if (redirectToAdmin) path = "/WebInterface/admin/index.html";

          _$("#loginForm").attr("action", path);

          link = path;
          utils.storage.set("redirectPath", redirectPath);
        }
        if (window.otpSubmit) {
          window.location = link;
          return false;
        }
        utils.storage.set("loginTime", new Date().getTime());
        loggedIn = true;
        console.log(loggedIn);
        const loginForm = document.getElementById("loginForm");
        loginForm.submit();
      } else if (type == "password_expired") {
        _$("#loginFormCard").addClass("is-hidden");
        _$("#changePasswordFormCard").removeClass("is-hidden");
        const authBanner = _$("#auth-banner");
        const brandingImagePath =
          window.brandingImagePath && utils.sanitize(window.brandingImagePath);

        authBanner.attr(
          "src",
          brandingImagePath ||
            "/WebInterface/new-ui/assets/images/forgot-password.png"
        );
        bodyLoaderWhileLoginFromQS?.deleteLoader();
        if (window.clearPasswordsAfterSubmit) {
          _$("#password, input[name=password]").val("");
        }
        window.curUser = username;
        window.curPass = password;
        // _badLogin = (msg && msg.getElementsByTagName && IE(msg.getElementsByTagName("message")[0]).textContent) || _badLogin;
        // _badLogin = unescape(_badLogin);
        growl(msg, t("changePassword"), 5000);
        utils.electronEvent("loginerror", { message: msg });
        //_$("#panelbody").hide();
        //_$("#changepasswordPanel").show().find("input").val("");
        //_$("#current_password").select().focus();
        loggedIn = false;
        delete window.smsOTP;
        delete window.isChanllenge;
        utils.cache.remove("smsOTP");
      } else if (type === "challenge" || type === "challenge_otp") {
        _$("#login").removeClass("is-hidden");
        bodyLoaderWhileLoginFromQS?.deleteLoader();
        // askforOTP(type == "challenge_otp");
        window.isChanllenge = type === "challenge";
        Prompt({
          title: t("enterYourOTPHere"),
          placeholder: "000000",
          confirm: t("submit"),
          cancel: "",
        }).then((res) => {
          if (res) {
            window.smsOTP = res.value || "";
            login();
          } else {
            console.log("user canceled");
          }
        });
        loggedIn = false;
      } else if (type === "challenge_code") {
        _$("#login").removeClass("is-hidden");
        bodyLoaderWhileLoginFromQS?.deleteLoader();
        // askforOTP(type == "challenge_otp");
        window.isChanllenge = true;
        growl(msg, t("Challenge"), 30000, true);
        setTimeout(() => {
          login();
        }, 1000);
        loggedIn = false;
      } else {
        if (window.clearPasswordsAfterSubmit) {
          _$("#password, input[name=password]").val("");
        }
        _$("#login").removeClass("is-hidden");
        bodyLoaderWhileLoginFromQS?.deleteLoader();
        _$("#password").el().focus();
        if (msg) {
          msg = msg.replace(
            "Check your username or password and try again.",
            "Your username, or password may be incorrect, or the account may have expired."
          );
        } else {
          msg = resp;
        }
        if (!window.hasCaptcha) {
          utils.electronEvent("loginerror", { message: msg });
        }
        growl(msg, t("loginFailed"), 5000, true);
        if (attempts++ >= 3) {
          var dummyElem = document.createElement("div");
          dummyElem.append(t("multipleBadLoginsAlert").replace("{msg}", msg));
          toast({ type: "warning", title: "", message: dummyElem.textContent });
        }
        loggedIn = false;
        delete window.smsOTP;
        utils.cache.remove("smsOTP");
      }
      if (!loggedIn && window.Recaptcha) {
        try {
          window.Recaptcha.reload();
        } catch (ex) {}
      }
      if (!loggedIn && window.grecaptcha) {
        try {
          window.grecaptcha.reset();
        } catch (ex) {}
      }
      window.otpSubmit = false;
    })
    .fail((err) => {
      if (window.clearPasswordsAfterSubmit) {
        _$("#password, input[name=password]").val("");
      }
      // let errorThrown = "Error";
      // if(typeof errorThrown.name !== undefined)
      // {
      //     var err = errorThrown.name + " : ";
      //     if(typeof errorThrown.message !== undefined)
      //         err += errorThrown.message;
      // }
      // errorThrown = errorThrown || "";
      // if(typeof errorThrown != "string") errorThrown = errorThrown.toString();
      // errorThrown = errorThrown.replace("Check your username or password and try again.",("BadLoginInfoText"));
      utils.electronEvent("loginerror", { message: err });
      growl(unescape(err), "_serverError", 5000, "true");
      //_$("#loginWheel").hide();
      loggedIn = false;
      delete window.smsOTP;
      utils.cache.remove("smsOTP");
    });
};

const processResetResponse = (msg) => {
  let response = decodeURIComponent(msg);

  if (
    response ===
    "This server is not configured to send email password reminders."
  ) {
    toast({
      type: "error",
      title: t("serverNotConfiguredForEmailError"),
      delay: 10000,
    });
  } else {
    toast({ type: "warning", title: response, delay: 10000 });
  }
  _$("#backToLogin").el().click();
};

const submitChangePasswordRequest = async () => {
  const current_password = _$("#change-password-current-password").val();
  const new_password = _$("#change-password-new-password").val();
  const confirm_password = _$("#change-password-confirm-password").val();

  const obj = {
    current_password,
    new_password1: new_password,
    new_password2: confirm_password,
  };

  try {
    const resp = await changePassword(obj);
    const result = resp?.commandResult?.response;

    if (result.toLowerCase() === "password changed.") {
      _$("#loginFormCard").removeClass("is-hidden");
      _$("#changePasswordFormCard").addClass("is-hidden");
      const authBanner = _$("#auth-banner");
      const brandingImagePath =
        window.brandingImagePath && utils.sanitize(window.brandingImagePath);

      authBanner.attr(
        "src",
        brandingImagePath || "/WebInterface/new-ui/assets/images/login.png"
      );

      growl(t("loginWithNewPassword"), t("pleaseLoginAgain"), 5000);
      return;
    }

    growl("", result, 5000, true);
  } catch (error) {
    console.log(error);
  }
};

const submitPasswordRequest = async () => {
  const username = _$("#reset-username").val();
  if (!username) {
    growl(t("enterValidUsername"), "", 5000, true);
    _$("#reset-username").el().focus();
    return;
  }

  const loading = new Loader({
    parentElement: "#authFormContainer",
  });

  const curLang = utils.storage.get("_i18n") || "en";

  const obj = {
    reset_username_email: encodeURIComponent(username),
    language: curLang,
    currentURL: `${document.location.protocol}//${document.location.host}${document.location.pathname}`,
    random: Math.random(),
  };

  try {
    const response = await resetPassword(obj);
    processResetResponse(response.commandResult.response);
  } catch (error) {
    const errorThrown = error.statusText;
    const responseText = error.responseText;

    if (responseText) {
      processResetResponse(responseText);
    } else {
      growl(
        t("theServerIsUnavailableOrYourIPHasBeenRemoved"),
        unescape(errorThrown),
        5000,
        true
      );
    }
  } finally {
    loading.deleteLoader();
  }
};

const bindEvents = () => {
  const authBanner = _$("#auth-banner");
  const brandingImagePath =
    window.brandingImagePath && utils.sanitizePure(window.brandingImagePath);

  _$("#forgotLink").on("click", () => {
    if (window.customizations?.login_page_email_reset_token === "true") {
      const resetURL =
        window.customizations?.login_page_reset_url ||
        "/WebInterface/new-ui/reset.html";

      location.href = resetURL;
      return;
    }

    _$("#loginFormCard").addClass("is-hidden");
    _$("#resetFormCard").removeClass("is-hidden");
    authBanner.attr(
      "src",
      brandingImagePath ||
        "/WebInterface/new-ui/assets/images/forgot-password.png"
    );
  });

  _$("#cancelChangePassword").on("click", () => {
    _$("#loginFormCard").removeClass("is-hidden");
    _$("#changePasswordFormCard").addClass("is-hidden");
    authBanner.attr(
      "src",
      brandingImagePath || "/WebInterface/new-ui/assets/images/login.png"
    );
  });

  _$("#btnGeneratePassword").on("click", () => {
    const generatedPassword = utils.generatePasswordBasedOnRules();
    _$("#generated_password").val(generatedPassword);
    _$("#generatePasswordPanel").removeClass("is-hidden");
  });

  _$("#usePassword").on("click", () => {
    const generatedPassword = _$("#generated_password").val();
    _$("#change-password-new-password").val(generatedPassword);
  });

  _$("#cancelPassword").on("click", () => {
    _$("#generatePasswordPanel").addClass("is-hidden");
  });

  _$("#backToLogin").on("click", () => {
    _$("#loginFormCard").removeClass("is-hidden");
    _$("#resetFormCard").addClass("is-hidden");
    authBanner.attr(
      "src",
      brandingImagePath || "/WebInterface/new-ui/assets/images/login.png"
    );
  });

  const loginForm = _$("#loginForm");
  loginForm.on("submit", (e) => {
    if (!loggedIn) {
      e.preventDefault();
      login();
      return false;
    }
  });

  const passwordRequestForm = _$("#passwordRequestForm");
  passwordRequestForm.on("submit", (e) => {
    e.preventDefault();
    submitPasswordRequest();
    return false;
  });

  const changePasswordForm = _$("#changePasswordForm");
  changePasswordForm.on("submit", (e) => {
    e.preventDefault();
    submitChangePasswordRequest();
    return false;
  });

  _$("#logout").on("click", (e) => {
    e.preventDefault();
    const c2f = utils.cookie.get("currentAuth");
    if (c2f) {
      const loading = new Loader({
        parentElement: "#authFormContainer",
      });
      ajax
        .call({
          data: {
            command: "logout",
            c2f,
          },
        })
        .then((resp) => {
          utils.cookie.remove("currentAuth");
          _$("#userLoggedIn").hide();
          loading.deleteLoader();
        });
    }
    return false;
  });
};

const submitForm = (password = "", username = "") => {
  _$("#password").val(password);
  _$("#username").val(username);
  login();
  setTimeout(function () {
    _$("#password").val("");
    _$("#username").val("");
  }, 100);
};

function init() {
  window.addEventListener("keydown", (e) => {
    if (e.key === "Alt" || e.altKey) altKeyPressed = true;
    if (e.key === "Meta" || e.metaKey) metaKeyPressed = true;
  });

  window.addEventListener("keyup", (e) => {
    if (e.key === "Alt" || e.altKey) altKeyPressed = false;
    if (e.key === "Meta" || e.metaKey) metaKeyPressed = false;
  });

  // console.log({ GSignClientID: window.GSignClientID });
  isLoggedIn()
    .then((result) => {
      const { response, username } = result;
      if (response == "success" && username.toLowerCase() !== "anonymous") {
        _$("#currentUserName").text(username);
        _$("#userLoggedIn").show();
      }
    })
    .catch((error) => {
      console.log(error);
    });
  const lastUserName = utils.storage.get("lastUserName");
  if (lastUserName) {
    _$("#username, input[name=username]").val(lastUserName);
  }

  if (
    window.GSignClientID ||
    window.MSSignClientID ||
    window.AmazonCognitoClientID ||
    window.MSSignClientIDB2C
  ) {
    _$("#crushAuthContainer").removeClass("is-hidden");
  }

  if (window.GSignClientID) {
    ajax.getScript(
      "https://accounts.google.com/gsi/client",
      () => {
        // New

        function handleCredentialResponse(response) {
          submitForm(response.credential, "crush_oauth2");
        }

        google.accounts.id.initialize({
          client_id: window.GSignClientID,
          callback: handleCredentialResponse,
        });

        _$("#google-signin").removeClass("is-hidden");

        google.accounts.id.renderButton(
          document.getElementById("google-signin"),
          { theme: "outline", size: "large" } // customization attributes
        );

        google.accounts.id.prompt();

        // _$("#gSigninButton").on("click", handleCredentialResponse)

        // Old

        // window.onSignIn = (auth) => {
        //   _$("#password").val(auth.credential);
        //   _$("#username").val("crush_oauth2");
        //   login();
        //   setTimeout(function () {
        //     _$("#password").val("");
        //     _$("#username").val("");
        //   }, 100);
        // };
        // _$("#google-signin").html(
        //   '<div id="g_id_onload" data-client_id="' +
        //   window.GSignClientID +
        //   '" data-callback="onSignIn"></div><div class="g_id_signin" data-type="standard"></div>'
        // );
      },
      () =>
        growl(
          t("problemWhileLoadingGoogleSignInLibrary"),
          "Error :",
          5000,
          true
        )
    );
  }

  if (window.MSSignClientID) {
    ajax.getScript(
      "//cdn.jsdelivr.net/npm/bluebird@3.7.2/js/browser/bluebird.min.js",
      () => {
        ajax.getScript(
          "https://alcdn.msauth.net/browser/2.0.0-beta.4/js/msal-browser.js",
          () => {
            const msalConfig = {
              auth: {
                clientId: window.MSSignClientID,
                authority:
                  "https://login.microsoftonline.com/" + window.MSTenantID,
                redirectUri: window.location.href.split("#")[0],
              },
              cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: false,
              },
            };
            const loginRequest = {
              scopes: ["openid", "profile", "User.Read"],
            };
            // const tokenRequest = {
            //   scopes: ["User.Read"]
            // };

            const myMSALObj = new msal.PublicClientApplication(msalConfig);
            let accessToken;
            let username = "";
            myMSALObj
              .handleRedirectPromise()
              .then(handleResponse)
              .catch((err) => {
                growl(
                  t("problemWithMicrosoftSignInLibraryConfiguration"),
                  "Error :",
                  5000,
                  true
                );
                console.error(err);
              });

            function handleResponse(resp) {
              if (resp !== null) {
                username = resp.account.username;
                accessToken = resp.accessToken;
              }
              if (accessToken) {
                submitForm(accessToken, "crush_oauth2_ms");
              }
            }

            const msSignInButton = _$("#msSigninButton");

            msSignInButton.removeClass("is-hidden");

            msSignInButton.on("click", () => {
              try {
                myMSALObj.loginRedirect(loginRequest).catch((ex) => {
                  console.log(ex);
                  growl(
                    t("problemWithMicrosoftSignInLibraryConfiguration"),
                    "Error :",
                    5000,
                    true
                  );
                });
              } catch (ex) {
                console.log(ex);
              }
            });
          },
          () => {
            growl(
              t("problemWhileLoadingMicrosoftSignInLibrary"),
              "Error :",
              5000,
              true
            );
          }
        );
      },
      () => {
        growl(
          t("problemWhileLoadingMicrosoftSignInLibrary"),
          "Error :",
          5000,
          true
        );
      }
    );
  }

  if (window.MSSignClientIDB2C) {
    const msB2CSigninButton = _$("#msB2CSigninButton");
    msB2CSigninButton.removeClass("is-hidden");

    const b2cConfig = {
      tenentName: window.tenantNameB2C,
      userFlowName: window.userFlowNameB2C,
      clientId: window.MSSignClientIDB2C,
      redirectUri: window.location.href.split("#")[0].split("?")[0],
      codeChallenge:
        btoa(Math.random().toString()).substr(2, 40) +
        btoa(Math.random().toString()).substr(2, 40),
    };

    function signInB2C() {
      const url = `https://${b2cConfig.tenentName}.b2clogin.com/${
        b2cConfig.tenentName
      }.onmicrosoft.com/oauth2/v2.0/authorize?p=${
        b2cConfig.userFlowName
      }&client_id=${
        b2cConfig.clientId
      }&nonce=defaultNonce&redirect_uri=${encodeURIComponent(
        b2cConfig.redirectUri
      )}&scope=openid&response_type=id_token&prompt=login`;
      window.location = url;
      return false;
    }

    msB2CSigninButton.on("click", () => {
      signInB2C();
    });

    function removeHash() {
      let scrollV,
        scrollH,
        loc = window.location;
      if ("pushState" in history)
        history.pushState("", document.title, loc.pathname + loc.search);
      else {
        // Prevent scrolling by storing the page's current scroll offset
        scrollV = document.body.scrollTop;
        scrollH = document.body.scrollLeft;
        loc.hash = "";
        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
      }
    }
    let hash = window.location.hash.substring(1);
    if (hash.startsWith("id_token")) {
      removeHash();
      const accessTokenB2C = hash.split("=")[1];
      submitForm(accessTokenB2C, "crush_oauth2_azure_b2c");
    }
  }

  if (window.AmazonCognitoClientID) {
    const amazonSigninButton = _$("#amazonSigninButton");
    amazonSigninButton.removeClass("is-hidden");

    amazonSigninButton.on("click", () => {
      let clientID = window.AmazonCognitoClientID;
      let domainPrefix = window.AmazonCognitoDomainPrefix;
      let redirectURL = window.location.href.split("#")[0].split("?")[0];

      const url = `https://${domainPrefix}.amazoncognito.com/login?client_id=${clientID}&scope=openid&response_type=code&redirect_uri=${encodeURIComponent(
        redirectURL
      )}`;
      window.location = url;
      return false;
    });

    function removeQS() {
      let scrollV,
        scrollH,
        loc = window.location;
      if ("pushState" in history)
        history.pushState("", document.title, loc.pathname);
      else {
        // Prevent scrolling by storing the page's current scroll offset
        scrollV = document.body.scrollTop;
        scrollH = document.body.scrollLeft;
        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
      }
    }

    let code = getQuerystring("code");
    if (code) {
      removeQS();
      const redirectURL = window.location.href.split("#")[0].split("?")[0];
      submitForm(
        code + "~" + encodeURIComponent(redirectURL),
        "crush_oauth2_cognito"
      );
    }
  }

  function initLoginFromQS() {
    let options = {
      path: "/",
      expires: -1,
    };
    const c2f = utils.cookie.get("currentAuth");
    utils.cookie.set("currentAuth", c2f, options);

    const lastUserName = utils.storage.get("lastUserName");
    if (lastUserName) {
      _$("#username").val(decodeURIComponent(lastUserName));
      _$("#remember").prop("checked", "checked");
      _$("input[name=password]").el().focus();
    }

    const userName = getQuerystring("u");
    const pass = getQuerystring("p");
    const reset = getQuerystring("r");
    const crushsync = getQuerystring("x-crushshare");
    const timedout = getQuerystring("timedout");

    if (timedout) utils.electronEvent("loggedout", { flag: true });
    else if (!window.hasCaptcha) {
      setTimeout(function () {
        utils.electronEvent("loggedout", { flag: true });
      }, 10000);
    }

    if (crushsync) {
      localStorage.setItem("crushsync", crushsync);
      _$("body").addClass("crushsync");
    } else {
      localStorage.setItem("crushsync", "");
    }

    if (userName) {
      _$("#username, #resetUserName").val(userName);
    }

    if (pass) _$("#password").val(pass);
    if (reset) showResetPanel();
    if (typeof window.Recaptcha != "undefined" && (userName || pass)) {
      _$("#username").closest("div").hide();
      _$("#password").closest("div").hide();
    }
    // let _login = getQuerystring("l");
    if (userName && pass) {
      bodyLoaderWhileLoginFromQS = new Loader({
        parentElement: "body",
      });
      let path = "/";
      let redirectPath = getQuerystring("path") || path;
      if (redirectPath) redirectPath = unescape(redirectPath);
      redirectPath = utils.sanitize(redirectPath);
      if (location.href.indexOf("/Web" + "Interface/login.html") >= 0)
        path =
          location.href.substring(
            0,
            location.href.indexOf("/Web" + "Interface/login.html")
          ) +
          "/#" +
          redirectPath;
      else path = location.href.replace("/#/", redirectPath);
      _$("#loginForm").attr("action", path);

      login();
      return;
    }

    _$("#login").removeClass("is-hidden");
  }

  initLoginFromQS();

  const acceptCookiesAllowedInStore = localStorage.getItem("acceptCookies");

  if (
    !acceptCookiesAllowedInStore &&
    !window.dontShowCookieNotification &&
    window.customizations?.login_page_disable_cookie_policy === "false"
  ) {
    _$("#acceptCookieNotification").removeClass("is-hidden");
    _$("#acceptCookieNotification").addClass("show");
  } else {
    _$("#acceptCookieNotification").el().remove();
    _$("#customFooterLoginPageContainer").css("bottom", "0");
  }

  const closeCookieNotification = () => {
    _$("#acceptCookieNotification").addClass("remove");
    _$("#acceptCookieNotification").on("animationend", (e) => {
      e.target.remove();
    });
    _$("#customFooterLoginPageContainer").css("bottom", "0");
  };

  _$("#cookiePolicyAcceptButton").on("click", () => {
    localStorage.setItem("acceptCookies", "true");
    closeCookieNotification();
  });

  _$("#cookiePolicyDismissButton").on("click", () => {
    closeCookieNotification();
  });

  _$("#acceptCookieNotificationCloseButton").on("click", () => {
    closeCookieNotification();
  });
}

const applyCustomizations = () => {
  const login_page_default_theme =
    window.customizations?.login_page_default_theme;
  const brandingImagePath =
    window.brandingImagePath && utils.sanitizePure(window.brandingImagePath);

  console.log(window.brandingImagePath);

  if (
    login_page_default_theme ||
    typeof login_page_default_theme === "string"
  ) {
    const [theme, hidePicker] = login_page_default_theme.split("-");
    _$("[data-theme]").attr("data-theme", theme);
    localStorage.setItem("data-theme", theme);
    console.log(hidePicker);
    if (hidePicker) {
      _$("w-theme").remove();
    }
  }

  if (window.customizations?.login_page_default_language) {
    setLanguage(window.customizations?.login_page_default_language);
  }

  if (window.customizations?.login_page_show_language_selection === "false") {
    _$("w-language").remove();
  }

  if (window.customizations?.login_page_languages_to_show) {
    utils.filterLanguagesToShow(
      window.customizations?.login_page_languages_to_show
    );
  }

  if (window.customizations?.login_page_column_style === "single") {
    _$(".auth-banner-container").remove();
  }

  if (window.customizations?.login_page_column_style === "double-reverse") {
    _$("#page-columns").addClass("is-flex-direction-row-reverse");
  }

  if (window.customizations?.login_page_save_languages_selection === "true") {
    const selectedLanguage = _$("html").attr("lang");
    localStorage.setItem("selected-language", selectedLanguage);
  }

  if (window.customizations?.login_page_privacy_policy_link) {
    _$("#cookiePolicyLink").attr(
      "href",
      window.customizations?.login_page_privacy_policy_link
    );
  }

  if (window.customizations?.login_page_hide_email_password === "true") {
    _$("#forgotLink").remove();
  }

  if (brandingImagePath) {
    const authBanner = _$("#auth-banner");
    authBanner.attr("src", brandingImagePath);
  }

  const autocompleteLoginForm =
    window.customizations?.login_page_autocomplete_off === "true"
      ? "off"
      : "on";
  if (autocompleteLoginForm === "off") {
    [
      "#username",
      "#password",
      "#reset-username",
      "#change-password-confirm-password",
      "#change-password-new-password",
      "#change-password-current-password",
    ].forEach((id) => _$(id).attr("autocomplete", autocompleteLoginForm));
  }
};

//On DOM Ready
_$("body").ready(() => {
  init();
  bindEvents();
  applyCustomizations();
});
